<template>
    <div  id="acerca" class="bg-degradado-green border-extra ">
    
        <section class="flex-container flex-row-reverse offset space-70">
         <transition name="slide-fade">
            <article class="flex-item" v-if="animation2">
                <img  loading="lazy"  class="img-auto" src="../assets/animacion/01/Yipi_AnimacionWeb_01.gif"/>
         
            </article>
         </transition>
   
        <Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
            <div class="height-div">
            <transition name="slide-fade">
                <article class="flex-item"  v-if="animation2">
                    <p class="title-1 margin-right-left-1"> <span class="green-weight">Yipi</span> es el primer club de electromovilidad en México</p>
                    <p class="paragraph-1 margin-right-left-1">Con 80% de su población viviendo en centros
                        urbanos, América Latina es la región en desarrollo 
                        con mayor demanda de movilidad de cortas distancias, <span class="green-weight">Yipi</span> es una solución de <span class="green-weight">Minimovilidad 
                            Sustentable</span> que complementa el transporte en las ciudades.
                    </p>
                </article>
            </transition>
            </div>
        </Intersect>  
        </section>


      
        <section class="flex-container offset">
            <transition name="slide-fade">
                <article class="flex-item" v-if="animation2">
                
                    <picture >
                        <source media="(max-width: 700px)" srcset="../assets/animacion/02/Yipi_AnimacionWeb_02_Ipad.gif">
                        <img  loading="lazy" class="img-auto" src="../assets/animacion/02/Yipi_AnimacionWeb_02.gif" alt="animation dashboard">
                    </picture>
                    
                </article>
            </transition>
         <Intersect @enter.once="animation2=true" @leave.once="animation2=false" :threshold="[1.0]">
             <div class="height-div">


            <transition name="slide-fade">
                <article class="flex-item" v-if="animation2">
                    <p class="title-1 margin-right-left-1"> ¿Quiénes somos?</p>
                    <p class="paragraph-1 margin-right-left-1"><span class="green-weight">Yipi</span> es un <span class="green-weight">ecosistema tecnológico de movilidad ecológica</span>
                        para las <span class="green-weight">cortas distancias</span>, que funciona dentro  de polígonos de servicio definidos, mediante app o 
                        tarjeta de prepago, y a través de <span class="green-weight">cuadriciclos eléctricos.</span>
                    </p>
                </article>
            </transition>
            </div>
         </Intersect>
        </section>
    

    </div>

</template>

<style >
 @import '../styles/quienesSomos.css';
 
</style>
<script>
  import Intersect from 'vue-intersect'

export default {
     components:  {Intersect}, 
     data:()=>({
         
         animation2:false
     }),
}
</script>
